import React from 'react'
import {
  countBasketItems,
  myCurrentBasket,
  removeMyBasket,
  setMyBasket,
} from '../services/basketService'

const initialState = {
  basketId: myCurrentBasket() ? myCurrentBasket().id : null,
  basketCurrency: myCurrentBasket() ? myCurrentBasket().currency : null,
  basketItems: null,
  basketWithTaxPrice: null,
  basketWithoutTaxPrice: null,
  basketTaxPrice: null,
  basketDiscountPrice: null,
  basketItemsCount: myCurrentBasket() ? myCurrentBasket().itemCount : 0,
  basketFeatureAvailable: true,
  basketExpires: myCurrentBasket() ? myCurrentBasket().expires : null,
  basketCertificateOfConformity: null,
  basketDeliveryCharge: null,
  warningMessages: [],
  basketIsReadonly: false,
  basketIsAboveMinimumResult: null,
}

const actions = {
  SET_ID: 'SET_ID',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_ITEMS: 'SET_ITEMS',
  SET_BASKET: 'SET_BASKET',
  SET_WITHTAXPRICE: 'SET_WITHTAXPRICE',
  SET_WITHOUTTAXPRICE: 'SET_WITHOUTTAXPRICE',
  SET_TAXPRICE: 'SET_TAXPRICE',
  SET_DISCOUNTPRICE: 'SET_DISCOUNTPRICE',
  SET_FEATUREAVAILABILITY: 'SET_FEATUREAVAILABILITY',
  SET_EXPIRES: 'SET_EXPIRES',
  SET_CERTIFICATEOFCONFORMITY: 'SET_CERTIFICATEOFCONFORMITY',
  SET_DELIVERYCHARGE: 'SET_DELIVERYCHARGE',
  DELETE_BASKET: 'DELETE_BASKET',
  SET_WARNINGMESSAGES: 'SET_WARNINGMESSAGES',
  SET_ISREADONLY: 'SET_ISREADONLY',
  SET_ISABOVEMINIMUMRESULT: 'SET_ISABOVEMINIMUMRESULT',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_ID: {
      const localStorageBasket = setMyBasket(action.value, null, 0)
      let newState = {
        ...state,
        basketId: action.value,
        basketCurrency: null,
        basketItems: null,
        basketWithTaxPrice: null,
        basketWithoutTaxPrice: null,
        basketTaxPrice: null,
        basketDiscountPrice: null,
        basketItemsCount: 0,
        basketExpires: null,
        basketCertificateOfConformity: null,
        basketDeliveryCharge: null,
        basketIsReadonly: false,
        basketIsAboveMinimumResult: null,
      }
      return newState
    }
    case actions.SET_CURRENCY: {
      let newState = { ...state, basketCurrency: action.value }
      return newState
    }
    case actions.SET_ITEMS: {
      let newState = {
        ...state,
        basketItems: action.value,
        basketItemsCount: countBasketItems(action.value),
      }
      return newState
    }
    case actions.SET_WITHTAXPRICE: {
      let newState = { ...state, basketWithTaxPrice: action.value }
      return newState
    }
    case actions.SET_WITHOUTTAXPRICE: {
      let newState = { ...state, basketWithoutTaxPrice: action.value }
      return newState
    }
    case actions.SET_TAXPRICE: {
      let newState = { ...state, basketTaxPrice: action.value }
      return newState
    }
    case actions.SET_DISCOUNTPRICE: {
      let newState = { ...state, basketDiscountPrice: action.value }
      return newState
    }
    case actions.SET_BASKET:
      const itemsCount = countBasketItems(action.value.items)
      const localStorageBasket = setMyBasket(action.value.id, action.value.currency, itemsCount)
      return {
        ...state,
        basketId: action.value.id,
        basketCurrency: action.value.currency,
        basketItems: action.value.items,
        basketWithTaxPrice: action.value.withTax,
        basketWithoutTaxPrice: action.value.withoutTax,
        basketTaxPrice: action.value.tax,
        basketDiscountPrice: action.value.discount,
        basketItemsCount: itemsCount,
        basketExpires: localStorageBasket.expires,
        basketCertificateOfConformity: action.value.certificateOfConformity,
        basketDeliveryCharge: action.value.deliveryCharge,
        basketIsReadonly: action.value.isReadonly,
        basketIsAboveMinimumResult: action.value.isOrderValueAboveMinimumResult,
      }
    case actions.SET_FEATUREAVAILABILITY: {
      let newState = { ...state, basketFeatureAvailable: action.value }
      return newState
    }
    case actions.DELETE_BASKET: {
      removeMyBasket()
      let newState = {
        ...state,
        basketId: null,
        basketCurrency: null,
        basketItems: null,
        basketWithTaxPrice: null,
        basketWithoutTaxPrice: null,
        basketTaxPrice: null,
        basketDiscountPrice: null,
        basketItemsCount: 0,
        basketExpires: null,
        basketCertificateOfConformity: null,
        basketDeliveryCharge: null,
        basketIsReadonly: false,
        basketIsAboveMinimumResult: null
      }
      return newState
    }
    case actions.SET_EXPIRES: {
      let newState = { ...state, basketExpires: action.value }
      return newState
    }
    case actions.SET_CERTIFICATEOFCONFORMITY: {
      let newState = { ...state, basketCertificateOfConformity: action.value }
      return newState
    }
    case actions.SET_DELIVERYCHARGE: {
      let newState = { ...state, basketDeliveryCharge: action.value }
      return newState
    }
    case actions.SET_WARNINGMESSAGES: {
      let newState = { ...state, warningMessages: action.value }
      return newState
    }
    case actions.SET_ISREADONLY: {
      let newState = { ...state, basketIsReadonly: action.value }
      return newState
    }
    case actions.SET_ISABOVEMINIMUMRESULT: {
      let newState = { ...state, basketIsAboveMinimum: action.value}
      return newState
    }
    default:
      return state
  }
}

const BasketContext = React.createContext(initialState)

export const BasketProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    basketId: state.basketId,
    basketCurrency: state.basketCurrency,
    basketItems: state.basketItems,
    basketItemsCount: state.basketItemsCount,
    basketFeatureAvailable: state.basketFeatureAvailable,
    basketWithTaxPrice: state.basketWithTaxPrice,
    basketWithoutTaxPrice: state.basketWithoutTaxPrice,
    basketTaxPrice: state.basketTaxPrice,
    basketDiscountPrice: state.basketDiscountPrice,
    basketExpires: state.basketExpires,
    basketCertificateOfConformity: state.basketCertificateOfConformity,
    basketDeliveryCharge: state.basketDeliveryCharge,
    warningMessages: state.warningMessages,
    basketIsReadonly: state.basketIsReadonly,
    basketIsAboveMinimumResult: state.basketIsAboveMinimumResult,
    setBasketId: (value) => {
      dispatch({ type: actions.SET_ID, value })
    },
    setBasketCurrency: (value) => {
      dispatch({ type: actions.SET_CURRENCY, value })
    },
    setBasketItems: (value) => {
      dispatch({ type: actions.SET_ITEMS, value })
    },
    setBasketWithTaxPrice: (value) => {
      dispatch({ type: actions.SET_WITHTAXPRICE, value })
    },
    setBasketWithoutTaxPrice: (value) => {
      dispatch({ type: actions.SET_WITHOUTTAXPRICE, value })
    },
    setBasketTaxPrice: (value) => {
      dispatch({ type: actions.SET_TAXPRICE, value })
    },
    setBasketDiscountPrice: (value) => {
      dispatch({ type: actions.SET_DISCOUNTPRICE, value })
    },
    setBasket: (value) => {
      dispatch({ type: actions.SET_BASKET, value })
    },
    setBasketFeatureAvailability: (value) => {
      dispatch({ type: actions.SET_FEATUREAVAILABILITY, value })
    },
    deleteBasket: () => {
      dispatch({ type: actions.DELETE_BASKET })
    },
    setBasketExpires: (value) => {
      dispatch({ type: actions.SET_EXPIRES, value })
    },
    setBasketCertificateOfConformity: (value) => {
      dispatch({ type: actions.SET_CERTIFICATEOFCONFORMITY, value })
    },
    setBasketDeliveryCharge: (value) => {
      dispatch({ type: actions.SET_DELIVERYCHARGE, value })
    },
    setWarningMessages: (value) => {
      dispatch({ type: actions.SET_WARNINGMESSAGES, value })
    },
    setIsReadonly: (value) => {
      dispatch({ type: actions.SET_ISREADONLY, value })
    },
    setIsOrderValueAboveMinimum: (value) => {
      dispatch({ type: actions.SET_ISABOVEMINIMUMRESULT, value })
    }
  }

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
}

export default BasketContext
