import { createBasket, getBasket } from './backendApiService'
import { getInsecureValue, removeInsecureValue, setInsecureValue } from './insecureStore'
import { logException } from './loggingService'

const BasketKey = 'basket'

export function myCurrentBasket() {
  return getInsecureValue(BasketKey)
}

export function getMyBasket(id, currency, locale, requestId, basketContext, language) {
  return new Promise((resolve, reject) => {
    if (id) {
      getBasket(id, currency, locale, requestId, basketContext, language)
        .then((data) => {
          if (data.basket) {
            const localStorageBasket = setMyBasket(
              data.basket.id,
              data.basket.currency,
              countBasketItems(data.basket.items)
            )
            resolve({
              basket: data.basket,
              basketAvailable: true,
              expires: localStorageBasket.expires,
              hasCertificateOfConformity: data.basket.hasCertificateOfConformity,
              requestId: requestId,
            })
          } else {
            reject({ error: 'basket unavailable', basketAvailable: false, requestId: requestId })
          }
        })
        .catch((error) => reject({ error: error, basketAvailable: false, requestId: requestId }))
    } else {
      createBasket(currency, locale, language, requestId)
        .then((data) => {
          if (data.basket) {
            const localStorageBasket = setMyBasket(data.basket.id, data.basket.currency, 0)
            resolve({
              basket: data.basket,
              basketAvailable: true,
              expires: localStorageBasket.expires,
              hasCertificateOfConformity: data.basket.hasCertificateOfConformity,
              requestId: requestId,
            })
          } else {
            reject({ error: 'basket unavailable', basketAvailable: false, requestId: requestId })
          }
        })
        .catch((error) => reject({ error: error, basketAvailable: false, requestId: requestId }))
    }
  })
}

export function removeMyBasket() {
  removeInsecureValue(BasketKey)
}

export function setMyBasket(id, currency, itemCount) {
  try {
    const basket = {
      id: id,
      currency: currency,
      itemCount: itemCount,
      expires: getExpiryDateTime(5),
    }
    setInsecureValue(BasketKey, basket)
    return basket
  } catch (error) {
    logException('Error when trying to set insecureValue', error)
  }
}

export function countBasketItems(basketItems) {
  try {
    let count = 0
    if (basketItems !== null && basketItems !== undefined) {
      for (let index = 0; index < basketItems.length; index++) {
        const basketItem = basketItems[index]
        if (basketItem.isMinimumOrderValueAdjustment && 
          basketItem.isMinimumOrderValueAdjustment === true) {
            // do nothing
        } else {
          count += basketItem.quantity
        }
      }
    }
    return count
  } catch (error) {
    logException('Error when trying to get basket item count', error)
  }
}

export function isBasketEmpty(basket) {
  return (
    basket['basketItems'] === null ||
    basket['basketItems'] === undefined ||
    basket['basketItems'].length === 0
  )
}

function getExpiryDateTime(minutes) {
  let date = new Date()
  date.setTime(date.getTime() + minutes * 60 * 1000)
  return date
}
